:root {
  --fill-opacity: 0 !important;
}

body {
  background-color: #e5e5e5;
  display: flex;
  justify-content: center;
}

img {
  height: auto;
  max-width: 100%;
}

svg {
  height: auto;
  width: 100%;
}

svg rect {
  cursor: pointer;
  fill-opacity: var(--fill-opacity);
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 750px;
}

.img-container {
  position: relative;
  height: 0;
  transition: padding 0.6s ease-out;
  width: 100%;
}
.collapsible {
  overflow: hidden;
}
.collapsible.is-closed {
  padding: 0 !important;
}

.overlay,
.img {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.mobile {
  display: flex;
}

.tablet {
  display: none;
}

.desktop {
  display: none;
}

@media screen and (min-width: 800px) {
  .container {
    max-width: 1125px;
  }

  .mobile {
    display: none;
  }

  .tablet {
    display: flex;
  }
}

@media screen and (min-width: 1600px) {
  .container {
    max-width: 1920px;
  }

  .tablet {
    display: none;
  }

  .desktop {
    display: flex;
  }
}

.desktop .img-1 {
  padding-top: unit(
    image-height(
        "./img/desktop/jet-0003-20-landingpage-hffs-mailing-1920x1080-px-rz-bildershow1.png"
      ) /
      image-width(
        "./img/desktop/jet-0003-20-landingpage-hffs-mailing-1920x1080-px-rz-bildershow1.png"
      ) * 100,
    %
  );
}
.desktop .img-2 {
  padding-top: unit(
    image-height(
        "./img/desktop/jet-0003-20-landingpage-hffs-mailing-1920x1080-px-rz-bildershow2.png"
      ) /
      image-width(
        "./img/desktop/jet-0003-20-landingpage-hffs-mailing-1920x1080-px-rz-bildershow2.png"
      ) * 100,
    %
  );
}
.desktop .img-3 {
  padding-top: unit(
    image-height(
        "./img/desktop/jet-0003-20-landingpage-hffs-mailing-1920x1080-px-rz-bildershow3.png"
      ) /
      image-width(
        "./img/desktop/jet-0003-20-landingpage-hffs-mailing-1920x1080-px-rz-bildershow3.png"
      ) * 100,
    %
  );
}
.desktop .img-4 {
  padding-top: unit(
    image-height(
        "./img/desktop/jet-0003-20-landingpage-hffs-mailing-1920x1080-px-rz-bildershow4.png"
      ) /
      image-width(
        "./img/desktop/jet-0003-20-landingpage-hffs-mailing-1920x1080-px-rz-bildershow4.png"
      ) * 100,
    %
  );
}
.desktop .img-5 {
  padding-top: unit(
    image-height(
        "./img/desktop/jet-0003-20-landingpage-hffs-mailing-1920x1080-px-rz-bildershow5.png"
      ) /
      image-width(
        "./img/desktop/jet-0003-20-landingpage-hffs-mailing-1920x1080-px-rz-bildershow5.png"
      ) * 100,
    %
  );
}
.desktop .img-6 {
  padding-top: unit(
    image-height(
        "./img/desktop/jet-0003-20-landingpage-hffs-mailing-1920x1080-px-rz-bildershow6.png"
      ) /
      image-width(
        "./img/desktop/jet-0003-20-landingpage-hffs-mailing-1920x1080-px-rz-bildershow6.png"
      ) * 100,
    %
  );
}
.desktop .img-7 {
  padding-top: unit(
    image-height(
        "./img/desktop/jet-0003-20-landingpage-hffs-mailing-1920x1080-px-rz-bildershow7.png"
      ) /
      image-width(
        "./img/desktop/jet-0003-20-landingpage-hffs-mailing-1920x1080-px-rz-bildershow7.png"
      ) * 100,
    %
  );
}
.desktop .img-8 {
  padding-top: unit(
    image-height(
        "./img/desktop/jet-0003-20-landingpage-hffs-mailing-1920x1080-px-rz-bildershow8.png"
      ) /
      image-width(
        "./img/desktop/jet-0003-20-landingpage-hffs-mailing-1920x1080-px-rz-bildershow8.png"
      ) * 100,
    %
  );
}
.desktop .img-9 {
  padding-top: unit(
    image-height(
        "./img/desktop/jet-0003-20-landingpage-hffs-mailing-1920x1080-px-rz-bildershow9.png"
      ) /
      image-width(
        "./img/desktop/jet-0003-20-landingpage-hffs-mailing-1920x1080-px-rz-bildershow9.png"
      ) * 100,
    %
  );
}
.desktop .img-10 {
  padding-top: unit(
    image-height(
        "./img/desktop/jet-0003-20-landingpage-hffs-mailing-1920x1080-px-rz-bildershow10.png"
      ) /
      image-width(
        "./img/desktop/jet-0003-20-landingpage-hffs-mailing-1920x1080-px-rz-bildershow10.png"
      ) * 100,
    %
  );
}

.tablet .img-1 {
  padding-top: unit(
    image-height(
        "./img/tablet/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow-108dpi1.png"
      ) /
      image-width(
        "./img/tablet/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow-108dpi1.png"
      ) * 100,
    %
  );
}
.tablet .img-2 {
  padding-top: unit(
    image-height(
        "./img/tablet/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow-108dpi2.png"
      ) /
      image-width(
        "./img/tablet/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow-108dpi2.png"
      ) * 100,
    %
  );
}
.tablet .img-3 {
  padding-top: unit(
    image-height(
        "./img/tablet/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow-108dpi3.png"
      ) /
      image-width(
        "./img/tablet/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow-108dpi3.png"
      ) * 100,
    %
  );
}
.tablet .img-4 {
  padding-top: unit(
    image-height(
        "./img/tablet/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow-108dpi4.png"
      ) /
      image-width(
        "./img/tablet/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow-108dpi4.png"
      ) * 100,
    %
  );
}
.tablet .img-5 {
  padding-top: unit(
    image-height(
        "./img/tablet/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow-108dpi5.png"
      ) /
      image-width(
        "./img/tablet/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow-108dpi5.png"
      ) * 100,
    %
  );
}
.tablet .img-6 {
  padding-top: unit(
    image-height(
        "./img/tablet/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow-108dpi6.png"
      ) /
      image-width(
        "./img/tablet/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow-108dpi6.png"
      ) * 100,
    %
  );
}
.tablet .img-7 {
  padding-top: unit(
    image-height(
        "./img/tablet/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow-108dpi7.png"
      ) /
      image-width(
        "./img/tablet/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow-108dpi7.png"
      ) * 100,
    %
  );
}
.tablet .img-8 {
  padding-top: unit(
    image-height(
        "./img/tablet/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow-108dpi8.png"
      ) /
      image-width(
        "./img/tablet/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow-108dpi8.png"
      ) * 100,
    %
  );
}
.tablet .img-9 {
  padding-top: unit(
    image-height(
        "./img/tablet/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow-108dpi9.png"
      ) /
      image-width(
        "./img/tablet/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow-108dpi9.png"
      ) * 100,
    %
  );
}
.tablet .img-10 {
  padding-top: unit(
    image-height(
        "./img/tablet/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow-108dpi10.png"
      ) /
      image-width(
        "./img/tablet/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow-108dpi10.png"
      ) * 100,
    %
  );
}

.mobile .img-1 {
  padding-top: unit(
    image-height(
        "./img/mobile/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow1.png"
      ) /
      image-width(
        "./img/mobile/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow1.png"
      ) * 100,
    %
  );
}
.mobile .img-2 {
  padding-top: unit(
    image-height(
        "./img/mobile/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow2.png"
      ) /
      image-width(
        "./img/mobile/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow2.png"
      ) * 100,
    %
  );
}
.mobile .img-3 {
  padding-top: unit(
    image-height(
        "./img/mobile/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow3.png"
      ) /
      image-width(
        "./img/mobile/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow3.png"
      ) * 100,
    %
  );
}
.mobile .img-4 {
  padding-top: unit(
    image-height(
        "./img/mobile/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow4.png"
      ) /
      image-width(
        "./img/mobile/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow4.png"
      ) * 100,
    %
  );
}
.mobile .img-5 {
  padding-top: unit(
    image-height(
        "./img/mobile/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow5.png"
      ) /
      image-width(
        "./img/mobile/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow5.png"
      ) * 100,
    %
  );
}
.mobile .img-6 {
  padding-top: unit(
    image-height(
        "./img/mobile/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow6.png"
      ) /
      image-width(
        "./img/mobile/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow6.png"
      ) * 100,
    %
  );
}
.mobile .img-7 {
  padding-top: unit(
    image-height(
        "./img/mobile/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow7.png"
      ) /
      image-width(
        "./img/mobile/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow7.png"
      ) * 100,
    %
  );
}
.mobile .img-8 {
  padding-top: unit(
    image-height(
        "./img/mobile/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow8.png"
      ) /
      image-width(
        "./img/mobile/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow8.png"
      ) * 100,
    %
  );
}
.mobile .img-9 {
  padding-top: unit(
    image-height(
        "./img/mobile/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow9.png"
      ) /
      image-width(
        "./img/mobile/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow9.png"
      ) * 100,
    %
  );
}
.mobile .img-10 {
  padding-top: unit(
    image-height(
        "./img/mobile/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow10.png"
      ) /
      image-width(
        "./img/mobile/jet-0003-20-landingpage-hffs-mailing-mobile-iphone8-rz-bildershow10.png"
      ) * 100,
    %
  );
}
